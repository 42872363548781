.solanaFMIcon {
  width: 100%;
  height: 100%;
}

.tensorLink {
  display: flex;
  align-items: center;
  justify-content: center;

  svg path {
    fill: var(--content-primary);
  }
}
