:root {
  //? Commons
  --border-less-primary: 0.5px solid var(--bg-border);
  --border-primary: 1px solid var(--bg-border);

  //? Colors - Dark Theme
  --pure-black: #000000;
  --pure-purple: #5865f2;
  --pure-white: #f5f5f5;

  --bg-primary: #181819;
  --bg-secondary: #151516;
  --bg-tertiary: #131314;
  --bg-border: #38383c;
  --bg-border-active: #8c8c8c;

  --bg-primary-gradient: linear-gradient(180deg, #14230e 0%, #131314 67%);

  --content-primary: #d3d3d3;
  --content-secondary: #8d8d8d;
  --content-tertiary: #656565;

  --action-primary: #202021;
  --action-secondary: #181818;
  --action-tertiary: #1c1c1d;

  --accent-primary: #61de2a;
  --accent-primary-sub: #2c511c;
  --accent-secondary: #61bc39;

  --additional-red-primary: #ff3347;
  --additional-red-primary-sub: #741b24;
  --additional-red-primary-deep: #e84749;
  --additional-red-secondary: #2a1215;

  --additional-lava-primary-deep: #e87040;
  --additional-lava-primary: #d84a1b;
  --additional-lava-secondary: #2b1611;

  --additional-orange-primary-deep: #e89a3c;
  --additional-orange-primary: #d87a16;
  --additional-orange-secondary: #2b1d11;

  --additional-gold-primary-deep: #e8b339;
  --additional-gold-primary: #d89614;
  --additional-gold-secondary: #2b2111;

  --additional-yellow-primary-deep: #e8d639;
  --additional-yellow-primary: #d8bd14;
  --additional-yellow-secondary: #2b2611;

  --additional-lime-primary-deep: #a9d134;
  --additional-lime-primary: #8bbb11;
  --additional-lime-secondary: #1f2611;

  --additional-green-primary: #49aa19;
  --additional-green-primary-deep: #6abe39;
  --additional-green-secondary: #1c2c17;

  --additional-blue-primary-deep: #3c9ae8;
  --additional-blue-primary: #2e9aff;
  --additional-blue-secondary: #111d2c;

  --additional-silver-primary: #798a9a;
  --additional-silver-primary-deep: #9eaeba;
  --additional-silver-secondary: #21272c;

  --additional-violet-primary: #7251f7;
  --additional-bronze-primary: #c97b21;

  //? Fonts
  --font-family-syne: 'Syne', sans-serif;
  --font-family-chakra: 'Chakra Petch', sans-serif;

  --title-3xl: 700 22px var(--font-family-syne);
  --title-2xl: 700 20px var(--font-family-syne);
  --title-xl: 700 18px var(--font-family-syne);
  --title-lg: 700 16px var(--font-family-syne);
  --title-md: 700 14px var(--font-family-syne);
  --title-sm: 700 12px var(--font-family-syne);
  --title-xs: 700 10px var(--font-family-syne);

  --important-text-3xl: 700 22px var(--font-family-chakra);
  --important-text-2xl: 700 20px var(--font-family-chakra);
  --important-text-xl: 700 18px var(--font-family-chakra);
  --important-text-lg: 700 16px var(--font-family-chakra);
  --important-text-md: 700 14px var(--font-family-chakra);
  --important-text-sm: 700 12px var(--font-family-chakra);
  --important-text-xs: 700 10px var(--font-family-chakra);

  --btn-text-md: 600 14px var(--font-family-chakra);
  --btn-text-sm: 600 12px var(--font-family-chakra);

  --body-text-xl: 400 18px var(--font-family-chakra);
  --body-text-lg: 400 16px var(--font-family-chakra);
  --body-text-md: 400 14px var(--font-family-chakra);
  --body-text-sm: 400 12px var(--font-family-chakra);
  --body-text-xs: 400 10px var(--font-family-chakra);
}
