// * Table * //
.table {
  min-width: 576px;

  tr > td,
  tr > th {
    &:first-child {
      width: 210px;
    }

    height: 48px;
    width: 100%;

    @media (max-width: 960px) {
      height: 38px;
    }
  }
}

.tableWrapper {
  height: 340px;
  position: relative;
}

.loader {
  margin-top: 132px;
}

// * Table * //

// * Cells * //
.nameCell {
  font: var(--title-md);

  max-width: 210px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  @media (max-width: 640px) {
    font: var(--title-sm);
  }
}

.votedCell {
  svg {
    height: 18px;
    width: 18px;
  }
}

.statusCell {
  border-radius: 100px;
  font: var(--body-text-sm);
  padding: 2px 8px;
  width: max-content;

  &.status-voting {
    color: var(--additional-blue-primary);
    background-color: var(--additional-blue-secondary);
  }

  &.status-completed {
    color: var(--additional-silver-primary-deep);
    background-color: var(--additional-silver-secondary);
  }
}

.durationCell {
  display: flex;
  flex-direction: column;
  gap: 2px;

  span:first-child {
    font: var(--body-text-md);

    @media (max-width: 640px) {
      font: var(--body-text-sm);
    }
  }

  span:last-child {
    font: var(--body-text-sm);

    @media (max-width: 640px) {
      font: var(--body-text-xs);
    }
  }
}

.resultsCell {
  height: 4px !important;
  width: 64px !important;
}

// * Cells * //

// * ManageModal * //
.manageModalContent {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;

  span {
    color: var(--content-secondary);
    font: var(--title-sm);
    text-transform: uppercase;
    white-space: nowrap;

    @media (max-width: 640px) {
      font: var(--title-xs);
    }
  }
}

.createVariantButton {
  margin-top: 8px;
  width: 100%;
}

// * ManageModal * //
