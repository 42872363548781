.rootSelectClassName {
  background: var(--bg-action);
  border-radius: 6px;
  color: var(--content-primary);
  margin-left: 32px;
  width: calc(100% - 32px);

  .ant-select-selector {
    background: var(--bg-action) !important;
    box-shadow: none !important;
    color: #d3d3d3;
    border: 0 !important;
    padding: 1px 0px;
    padding-inline-end: 40px !important;
    transition: none !important;
  }

  .ant-select-selection-item {
    display: none;

    background: var(--additional-lime-secondary);
    border: 1px solid var(--accent-primary);
    border-radius: 100px;
    font: var(--card-header-sm);

    padding-inline-start: 8px;
    padding-inline-end: 8px;
    margin-inline-start: 8px;

    .ant-select-selection-item-content img,
    .searchSelectImageContainer {
      border-radius: 100px;
      height: 16px;
      width: 16px;
    }

    .ant-select-selection-item-remove {
      &::before {
        content: '';
        background: var(--content-primary);
        display: flex;
        height: 1px;
        width: 10px;
      }
      span {
        display: none;
      }
    }

    .serchSelectFavoriteIcon,
    .searchSelectImageContainer > div,
    .searchSelectImageContainer ~ svg {
      display: none;
    }
  }

  .ant-select-selection-placeholder {
    color: var(--content-secondary);
    font: var(--body-text-lg);

    @media (max-width: 640px) {
      font: var(--body-text-md);
    }
  }

  .ant-select-selection-item-remove span {
    line-height: 0;
  }

  .ant-select-selection-item-content {
    display: flex !important;
    align-items: center;

    & > div > p {
      display: none;
    }
  }

  .ant-select-selection-overflow {
    flex-wrap: nowrap;
    overflow: auto;
  }

  ::-webkit-scrollbar {
    height: 0px;
  }
}

/* Icons styles */
.rootSelectClassName {
  .ant-select-arrow,
  .ant-select-clear {
    svg {
      path,
      rect {
        fill: var(--content-primary);
      }
    }
  }
  .ant-select-clear {
    background: none;
    cursor: pointer;
    opacity: 1;
    right: 16px;

    padding-bottom: 10px;
    padding-top: 10px;

    margin-right: -16px;
    margin-top: -16px;

    height: 32px;
    width: 50px;

    svg {
      width: 12px;
    }
  }
}

.rootSelectPopupClassName {
  background: var(--bg-primary);
  border: var(--border-primary);
  border-top: 0;
  border-radius: 0;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  box-shadow: none;

  font: var(--card-header-sm);

  top: 31px !important;
  left: -65px !important;

  padding: 0;
  width: calc(100% + 34px) !important;

  .ant-select-item {
    border-radius: 0;
    color: var(--content-primary);
    padding: 4px 16px;
    transition: none;
  }

  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background: var(--bg-action) !important;
  }

  .ant-select-item-option-selected {
    color: var(--content-primary) !important;
    background: var(--bg-primary) !important;
    font-weight: 400 !important;
  }

  .ant-select-item-option-state {
    display: none;
  }
}
