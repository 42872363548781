.rootSliderClassName {
  margin: 0 !important;

  .ant-slider-rail,
  &:hover .ant-slider-rail {
    background: var(--accent-primary-sub) !important;
    height: 4px;
  }

  .ant-slider-track {
    background: var(--accent-primary);
    border: 2px solid var(--accent-primary);
  }

  &:hover .ant-slider-track {
    background: var(--accent-primary);
    border: 2px solid var(--accent-primary);
  }

  .ant-slider-dot,
  &:hover .ant-slider-dot {
    background: var(--bg-primary);
    border: 2px solid var(--accent-primary-sub) !important;
    height: 8px;
    width: 8px;
  }

  .ant-slider-dot-active,
  &:hover .ant-slider-dot-active {
    background: var(--bg-primary);
    border: 2px solid var(--accent-primary) !important;
  }

  .ant-slider-mark {
    top: 14px;
  }

  .ant-slider-mark-text {
    color: var(--content-secondary);
    font: var(--body-text-sm);
  }

  .ant-slider-mark-text-active {
    color: var(--content-primary);
  }

  .ant-slider-handle {
    background: var(--bg-primary);
    border: 2px solid var(--accent-primary);
    box-shadow: unset !important;
  }

  .ant-slider-handle:after,
  .ant-slider-handle::before {
    display: none;
  }

  .ant-slider-handle {
    border-radius: 100%;
    position: relative;
    margin-top: -7px;
    height: 14px;
    width: 14px;

    &:focus,
    &:hover {
      box-shadow: unset;
    }
  }
}

.sliderDisabled {
  .ant-slider-rail,
  &:hover .ant-slider-rail {
    background: var(--content-secondary) !important;
  }

  .ant-slider-handle {
    background: var(--bg-primary);
    border: 2px solid var(--content-secondary);
  }
}

.sliderValue {
  .ant-slider-handle {
    &::before {
      inset-inline-start: unset !important;
      inset-block-start: unset !important;

      display: block;
      content: attr(aria-valuenow) '';
      background: unset;

      color: var(--content-primary);
      font: var(--body-text-sm);
      width: unset !important;
      height: unset !important;
      text-align: center;
      padding: 0 !important;
      position: absolute;
      top: 12px !important;
      left: 50% !important;
      transform: translateX(-50%);

      @media screen and (max-width: 640px) {
        font: var(--body-text-xs);
      }
    }
  }
}

.sliderWithValue {
  .sliderValue();
}
.sliderWithValueSol {
  .sliderValue();
  .ant-slider-handle {
    &::before {
      min-width: 56px;
      content: attr(aria-valuenow) ' SOL';
    }
  }
}
.sliderWithValuePercent {
  .sliderValue();
  .ant-slider-handle {
    &::before {
      content: attr(aria-valuenow) '%';
    }
  }
}
