@import './base/reset.less';
@import './base/scrollBar.less';
@import './abstracts/fonts.less';
@import './abstracts/variables.less';

@import './antd/table.less';
@import './antd/select.less';
@import './antd/slider.less';
@import './antd/tooltip.less';

:root {
  color-scheme: dark;
}

body {
  color: var(--content-primary);
  font: var(--body-text-md);

  background: linear-gradient(165deg, #0f0f0f 50%, #9cff1f 1000%);

  @media (max-width: 960px) {
    background: var(--bg-secondary);
  }
}
