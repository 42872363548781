//? Content
.content {
  margin: 108px auto 0;
  max-width: 960px;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  margin-bottom: 24px;

  @media (max-width: 640px) {
    margin: 82px auto 0;
    row-gap: 16px;
    margin-bottom: 16px;
  }
}

.proposalsBlock {
  background-color: var(--bg-tertiary);
  border: var(--border-less-primary);
  border-radius: 12px;

  @media (max-width: 960px) {
    border-radius: unset;
  }
}

.proposalsContentHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  padding: 24px;

  @media (max-width: 640px) {
    margin-bottom: 16px;
    padding: 16px;
  }
}

.proposalsContentTitle {
  color: var(--content-primary);
  font: var(--title-2xl);
  text-transform: uppercase;
  line-height: normal;

  @media (max-width: 640px) {
    font: var(--title-xl);
  }
}

// * CreateProposalModal * //
.createModalContent {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;

  span {
    color: var(--content-secondary);
    font: var(--title-sm);
    text-transform: uppercase;
    white-space: nowrap;

    @media (max-width: 640px) {
      font: var(--title-xs);
    }
  }
}

.createProposalButton {
  margin-top: 8px;
  width: 100%;
}

// * CreateProposalModal * //
