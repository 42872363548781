.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  margin: 12px 24px;

  @media (max-width: 960px) {
    margin: 8px 16px;
  }

  @media (max-width: 640px) {
    flex-direction: column;
    gap: 8px;
  }
}

.emptyList {
  background: var(--additional-blue-secondary);
  border: 0.5px solid var(--additional-blue-primary);
  border-radius: 6px;

  display: flex;
  align-items: center;

  padding: 6px 16px;
  min-height: 32px;
  width: 100%;

  @media (max-width: 640px) {
    text-align: center;
  }
}

.emptyListMessage {
  font: var(--body-text-md);
}

.emptyListButton {
  text-decoration: none;
}
