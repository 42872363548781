.root {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  padding: 16px;
  background: var(--bg-secondary);
}

.content {
  max-width: 675px;
  display: flex;
  flex-direction: column;
}

.image {
  margin: 0 auto;
  width: 32%;
  position: relative;

  &::after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
}

.title {
  text-align: center;
  color: var(--content-primary);
  font: var(--title-sm);
  margin-bottom: 16px;
  text-transform: uppercase;

  @media screen and (max-width: 640px) {
    font: var(--title-xs);
    margin-bottom: 8px;
  }
}

.subtitle {
  text-align: center;
  color: var(--content-primary);
  font: var(--body-text-lg);
  margin-bottom: 24px;

  @media screen and (max-width: 640px) {
    font: var(--body-text-md);
    margin-bottom: 16px;
  }
}

.discordLink {
  cursor: pointer;

  &,
  &:hover,
  &:focus,
  &:focus-visible,
  &:visited {
    color: var(--blue-color);
    border: unset;
  }
}

.errorMessage {
  background: var(--bg-primary);
  border: var(--border-less-primary);
  border-style: dashed;
  font: var(--body-text-lg);

  overflow-y: auto;
  max-height: 200px;
  margin-bottom: 16px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  white-space: pre-wrap;

  @media screen and (max-width: 640px) {
    font: var(--body-text-md);
  }
}
