.badge {
  border-radius: 100px;
  font: var(--body-text-sm);
  padding: 2px 8px;
  width: max-content;

  &.status-voting {
    color: var(--additional-blue-primary);
    background-color: var(--additional-blue-secondary);
  }

  &.status-completed {
    color: var(--additional-silver-primary-deep);
    background-color: var(--additional-silver-secondary);
  }
}
