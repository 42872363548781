// * Common * //
.content {
  margin: 108px auto 0;
  max-width: 960px;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  margin-bottom: 24px;

  @media (max-width: 640px) {
    margin: 82px auto 0;
    row-gap: 16px;
    margin-bottom: 16px;
  }
}

.twoColumns {
  display: grid;
  grid-template-columns: 55% 45%;
  align-items: stretch;

  @media (min-width: 641px) {
    & > * {
      &:first-child {
        border-radius: 12px 0 0 12px;
      }

      &:last-child {
        border-radius: 0 12px 12px 0;
      }
    }
  }

  @media (max-width: 640px) {
    grid-template-columns: 1fr;
    column-gap: unset;
    row-gap: 16px;
  }
}

.contentBlock {
  background-color: var(--bg-tertiary);
  border: var(--border-less-primary);
  border-radius: 12px;

  padding: 24px;

  @media (max-width: 960px) {
    padding: 16px;
  }

  @media (max-width: 640px) {
    border-radius: unset;
    border-right: 0;
    border-left: 0;
  }
}

.contentTitle {
  color: var(--content-primary);
  font: var(--title-3xl);
  text-transform: uppercase;
  line-height: normal;

  @media (max-width: 640px) {
    font: var(--title-2xl);
  }
}
// * Common * //

// * MainBlock * //
.mainBlockHeader {
  display: flex;
  column-gap: 8px;
  font: var(--title-2xl);
  line-height: normal;

  @media (max-width: 640px) {
    font: var(--title-xl);
  }
}

.mainBlockTitle {
  margin-bottom: 24px;

  @media (max-width: 640px) {
    margin-bottom: 16px;
  }
}

.mainBlockText {
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  font: var(--body-text-lg);

  @media (max-width: 640px) {
    font: var(--body-text-md);
  }
}

.mainBlockIcons {
  display: flex;
  column-gap: 12px;

  svg path {
    fill: var(--content-primary);
  }

  /* Special styling for second link (Discord icon) */
  a:nth-child(2) {
    svg path:not(:first-child) {
      fill: var(--bg-primary);
    }

    &:hover {
      svg path:not(:first-child) {
        fill: var(--bg-primary);
      }
    }
  }
}
// * MainBlock * //

// * StakingBlock * //
.stakingBlock {
  background-color: var(--action-primary);
  border: var(--border-less-primary);

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 960px) and (min-width: 641px) {
    border-radius: 12px 0 0 12px;
  }
}

.stakingBlockTitle {
  font: var(--body-text-lg);
  margin-bottom: 12px;

  @media (max-width: 640px) {
    font: var(--body-text-md);
  }
}

.stakingBlockBtn {
  text-decoration: none;

  button {
    width: 100%;
  }

  span {
    font: var(--btn-text-md);
    @media (max-width: 640px) {
      font: var(--btn-text-sm);
    }
  }
}
// * StakingBlock * //

// * VotesInfoBlock * //
.voteInfoBlock {
  border-right: 0;
}

.voteBlockTitle {
  font: var(--title-2xl);
  line-height: normal;

  margin-bottom: 14px;

  @media (max-width: 640px) {
    font: var(--title-xl);
  }
}

.voteBlockNotConnected {
  display: flex;
  flex-direction: column;
  gap: 8px;

  span {
    font: var(--body-text-lg);

    @media (max-width: 640px) {
      font: var(--body-text-md);
    }
  }

  button {
    max-width: 146px;

    span {
      font: var(--btn-text-md);
    }
  }
}

.voteBlockStats {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-top: 10px;

  span:first-child {
    font: var(--important-text-2xl);

    @media (max-width: 640px) {
      font: var(--important-text-xl);
    }
  }

  span:last-child {
    color: var(--content-secondary);
    font: var(--title-md);
    text-transform: uppercase;

    @media (max-width: 640px) {
      font: var(--title-sm);
    }
  }
}
// * VotesInfoBlock * //
