.cardList {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(348px, 2fr));
  padding: 8px 24px;
  gap: 16px;

  @media (max-width: 960px) {
    grid-template-columns: unset;
    padding: 8px 0;
    gap: 0;
  }
}

.card {
  background: var(--bg-primary);
  border: var(--border-primary);
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 4px;
  overflow-x: hidden;

  @media (max-width: 960px) {
    border-left: 0;
    border-right: 0;

    &:not(:last-child) {
      border-bottom: 0;
    }
  }
}

.cardRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 24px;

  &:first-child {
    justify-content: flex-start;
  }
}

.cardRowTitle {
  color: var(--content-secondary);
  font: var(--title-md);
  text-transform: uppercase;
}

.cardRow:first-child .cardRowTitle {
  display: none;
}

.cardRow button {
  padding: 9px !important;
  width: 100%;
}
