.header {
  background: var(--bg-secondary);
  border-bottom: var(--border-primary);

  position: fixed;
  width: 100%;
  z-index: 99;

  padding: 0 24px;

  @media (max-width: 960px) {
    padding: 0 16px;
  }
}

.headerContent {
  position: relative;
  margin: 0 auto;
  max-width: 960px;
  display: flex;
  align-items: center;
  padding: 12px 0;

  @media (max-width: 960px) {
    padding: 8px 0;
  }
}

.widgetContainer {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 24px;

  @media (max-width: 640px) {
    gap: 8px;
  }

  a {
    text-decoration: none;
  }
}

.rewardsButton {
  display: flex;
  align-items: center;
  gap: 8px;
}
.rewardsButtonText {
  display: flex;
  align-items: center;
  gap: 4px;
}

.logoWrapper {
  height: 32px;

  svg path {
    fill: var(--accent-primary);
  }

  .logoMobile {
    display: none;
  }

  @media screen and (max-width: 960px) {
    .logo {
      display: none;
    }

    .logoMobile {
      display: flex;
    }
  }
}
