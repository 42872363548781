// * Common * //
.content {
  margin: 108px auto 0;
  max-width: 960px;

  display: flex;
  align-items: flex-start;
  gap: 24px;
  width: 100%;

  @media (max-width: 640px) {
    flex-direction: column;
    margin: 82px auto 0;
    row-gap: 16px;
    margin-bottom: 16px;
  }
}

.mainColumns {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;

  @media (max-width: 640px) {
    gap: 16px;
  }
}

.additionalColumns {
  display: flex;
  flex-direction: column;
  gap: 24px;
  min-width: 296px;

  @media (max-width: 640px) {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px;
    width: 100%;

    & > div {
      width: 100%;
    }
  }
}

.contentBlock {
  background-color: var(--bg-tertiary);
  border: var(--border-less-primary);
  border-radius: 12px;

  padding: 24px;

  @media (max-width: 640px) {
    border-radius: unset;
    padding: 16px;
  }
}
// * Common * //

// * GeneralProposalInfo * //
.proposalInfoContent {
  display: flex;
  flex-direction: column;
  gap: 24px;

  h2 {
    color: var(--content-primary);
    font: var(--title-3xl);
    text-transform: uppercase;
    line-height: normal;

    @media (max-width: 640px) {
      font: var(--title-2xl);
    }
  }

  span {
    font: var(--body-text-lg);

    @media (max-width: 640px) {
      font: var(--body-text-md);
    }
  }
}

.backButton {
  margin-bottom: 12px;

  svg {
    transform: rotate(90deg);
    height: 16px;
    width: 16px;
  }
}
// * GeneralProposalInfo * //

// * VotesInfoBlock * //
.votesInfoHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;

  h3 {
    font: var(--title-2xl);
    text-transform: uppercase;

    @media (max-width: 640px) {
      font: var(--title-xl);
    }
  }

  span {
    font: var(--body-text-xl);

    @media (max-width: 640px) {
      font: var(--body-text-lg);
    }
  }
}

.votesInfoProgressBar {
  border-radius: 6px;
  position: relative;
  margin: 12px 0;
  height: 24px;
  width: 100%;
}

.votesInfoProgressBarItem {
  position: absolute;
  height: 100%;
  width: 100%;

  &:first-child {
    border-radius: 6px 0 0 6px;
  }

  &:last-child {
    border-radius: 0 6px 6px 0;
  }
}

.votesInfoListItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 20px;

  &:nth-child(odd) {
    background-color: var(--bg-secondary);
  }
}

.votesInfoListItemLabel {
  display: flex;
  align-items: center;
  gap: 4px;

  span {
    color: var(--content-tertiary);
    font: var(--title-sm);
    text-transform: uppercase;

    @media (max-width: 640px) {
      font: var(--title-xs);
    }
  }
}

.votesInfoListItemDot {
  border-radius: 100px;
  width: 8px;
  height: 8px;
}

.votesInfoListItemValue {
  span {
    color: var(--content-primary);
    font: var(--body-text-md);

    @media (max-width: 640px) {
      font: var(--body-text-sm);
    }
  }
}

.votesEmptyList {
  margin: 0;
  margin-top: 24px;

  div {
    padding: 6px 12px;
  }
}
// * VotesInfoBlock * //

// * StatusInfoBlock * //
.statusInfoList {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.statusInfoItem {
  display: flex;
  align-items: center;
  gap: 8px;
  white-space: nowrap;
}

.statusInfoItemLabel {
  color: var(--content-tertiary);
  font: var(--title-sm);
  text-transform: uppercase;

  display: flex;
  align-items: center;
  gap: 4px;

  @media (max-width: 640px) {
    font: var(--title-xs);
  }
}

.statusInfoItemValue {
  font: var(--body-text-md);

  @media (max-width: 640px) {
    font: var(--body-text-sm);
  }
}

.statusInfoItemLabelIconRotate {
  transform: rotate(180deg);
}
// * StatusInfoBlock * //

// * VoteActionBlock * //
.actionVoteButton {
  margin-top: 24px;
  width: 100%;

  button {
    width: 100%;
  }
}

.variantControls {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 24px;
}

.variantControlsItem {
  padding: 6px 14px;
  border-color: var(--bg-border) !important;
  min-width: 60px;

  &:nth-child(1) {
    background-color: var(--additional-green-secondary);
    color: var(--additional-green-primary);

    &.active {
      border-color: var(--additional-green-primary) !important;
    }
  }

  &:nth-child(2) {
    background-color: var(--additional-red-secondary);
    color: var(--additional-red-primary-deep);

    &.active {
      border-color: var(--additional-red-primary-deep) !important;
    }
  }

  &:nth-child(3) {
    background-color: var(--additional-blue-secondary);
    color: var(--additional-blue-primary-deep);

    &.active {
      border-color: var(--additional-blue-primary-deep) !important;
    }
  }

  &:nth-child(4) {
    background-color: var(--additional-gold-secondary);
    color: var(--additional-gold-primary-deep);

    &.active {
      border-color: var(--additional-blue-gold-deep) !important;
    }
  }

  &:disabled {
    background-color: var(--action-tertiary) !important;
    color: 1px solid var(--content-tertiary) !important;
    pointer-events: none;
  }
}
// * VoteActionBlock * //
