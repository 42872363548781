.emptyList {
  background: var(--additional-blue-secondary);
  border: 0.5px solid var(--additional-blue-primary);
  border-radius: 6px;

  padding: 6px 16px;
  margin: 16px 24px;

  @media (max-width: 960px) {
    margin: 8px 16px;
  }
}

.tableWrapper {
  flex-grow: 1;
  overflow-y: scroll;
  height: 100vh;
}
