.proposalsBlock {
  background-color: var(--bg-tertiary);
  border: var(--border-less-primary);
  border-radius: 12px;

  @media (max-width: 640px) {
    border-radius: unset;
  }
}

.proposalsContentHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;

  @media (max-width: 640px) {
    padding: 16px;
  }
}

.proposalsContentTitle {
  color: var(--content-primary);
  font: var(--title-2xl);
  text-transform: uppercase;
  line-height: normal;

  @media (max-width: 640px) {
    font: var(--title-xl);
  }
}
